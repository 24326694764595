export default class Product {
    constructor(id, code, name, description, type, imageUrl, cost, condition, isReplaceable, replaceDiscount) {
        this.id = id
        this.code = code
        this.name = name;
        this.description = description;
        this.type = type;
        this.imageUrl = imageUrl;
        this.cost = cost;
        this.condition = condition;
        this.isReplaceable = isReplaceable;
        this.replaceDiscount = replaceDiscount;
    }
}