import firebase from "firebase/app"
import "firebase/storage";
import "firebase/firestore";
import "firebase/auth"
import {v4 as uuidv4} from 'uuid';
import Product from "./Product";

export const initializeFirebaseApp = async () => {
    try {
        return firebase.app()
    } catch {
        // const res = await axios.get(`/__/firebase/init.json`);
        // const firebaseConfig = res.data
        const firebaseConfig = {
            apiKey: "AIzaSyAZbaMXVWcYiFEj4wdMZg0-YUDY66TMf8k",
            authDomain: "opal-15e0b.firebaseapp.com",
            projectId: "opal-15e0b",
            storageBucket: "opal-15e0b.appspot.com",
            messagingSenderId: "615433062167",
            appId: "1:615433062167:web:99e55c8843e50a9bd7d952",
            measurementId: "G-RYN91KWW2K"
        };

        if(firebaseConfig.apiKey) {
            try {
                return firebase.initializeApp(firebaseConfig);
            } catch {
                return undefined
            }
        } else {
            return undefined
        }
    }
};

export const login = async (email, password) => {
    try {
        await firebase.auth().signInWithEmailAndPassword(email, password);
    } catch (e) {
        throw  e
    }
}

export const isLoggedIn = () => {
    try {
        return !!firebase.auth().currentUser;
    } catch (e) {
        return false
    }
}

const removeImage = async (path) => {
    const ref = firebase.storage().ref().child(path);
    try {
        // see if file exists
        await ref.getDownloadURL()
    } catch (e) {
        return true
    }

    return await ref.delete()
};

export const removeProduct = async (id, url) => {
    try {
        const ref = firebase.firestore().collection("products").doc(id)
        await removeImage(url)
        await ref.delete()

        return true
    } catch (e) {
        return false
    }
}

export const addProduct = async (product) => {
    try {
        const ref = firebase.firestore().collection("products")

        await ref.add({
            code: product.code,
            name: product.name,
            description: product.description,
            type: product.type,
            imageUrl: product.imageUrl,
            cost: product.cost,
            condition: product.condition,
            isReplaceable: product.isReplaceable,
            replaceDiscount: product.replaceDiscount
        });

        return true
    } catch (e) {
        return false
    }
}

export const updateProduct = async (product) => {
    try {
        const ref = firebase.firestore().collection("products").doc(product.id)

        await ref.set({
            code: product.code,
            name: product.name,
            description: product.description,
            type: product.type,
            imageUrl: product.imageUrl,
            cost: product.cost,
            condition: product.condition,
            isReplaceable: product.isReplaceable,
            replaceDiscount: product.replaceDiscount
        });

        return true
    } catch (e) {
        return false
    }
}

export const getProducts = async () => {
    try {
        let result = []
        const querySnapshot = await firebase.firestore().collection("products").get()

        querySnapshot.forEach((doc) => {
            const data = doc.data()
            const product = new Product(
                doc.id,
                data.code,
                data.name,
                data.description,
                data.type,
                data.imageUrl,
                data.cost,
                data.condition,
                data.isReplaceable,
                data.replaceDiscount
            )
            result.push(product)
        });

        return result
    } catch (e) {
        return []
    }
}

export const downloadFile = async (path) => {
    const ref = firebase.storage().ref().child(path);

    try {
        return await ref.getDownloadURL()
    } catch (e) {
        return ""
    }
};

export const uploadProductImage = async (file, currentUrl) => {
    if(file === undefined) {
        return currentUrl
    }

    const name = uuidv4() + "-" + file.name;
    const ref = firebase.storage().ref().child("products").child(name);

    try {
        if(currentUrl !== "" || currentUrl !== undefined) {
            await removeImage(currentUrl)
        }

        await ref.put(file);
        return "products/" + name
    } catch (e) {
        return undefined
    }
};

export const uploadFiles = async (files) => {
    let result = [];
    let refs = [];

    const storage = firebase.storage();

    try {
        for (const f of files) {
            const ref = storage.ref().child("quotes").child(uuidv4() + "-" + f.name);
            const snapshot = await ref.put(f);

            result.push(await snapshot.ref.getDownloadURL());
            refs.push(ref);
        }

        return result
    } catch (e) {
        for (const ref of refs) {
            ref.delete().then(() => {})
        }

        throw e
    }
};