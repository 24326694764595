const React = require("react");
const { store } = require('./src/utils/store');
const { Provider } = require("react-redux");
const { ReferenceDataContextProvider } = require("./src/businessLogic/ReferenceDataContext");

exports.wrapRootElement = ({ element }) => {
    return (
        <Provider store={store}>
            <ReferenceDataContextProvider>
                {element}
            </ReferenceDataContextProvider>
        </Provider>
    )
};