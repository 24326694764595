import React, { createContext, useState, useEffect } from "react";

export const ReferenceDataContext = createContext({});

export const ReferenceDataContextProvider = ({ children }) => {
    const [firebaseApp, setFirebaseApp] = useState(undefined);

    useEffect(() => {
        if(typeof window !== "undefined") {
            const {initializeFirebaseApp} = require("../businessLogic/Firebase");
            initializeFirebaseApp().then((app) => {
                setFirebaseApp(app)
            })
        }
    }, [])

    return (
        <ReferenceDataContext.Provider value={{ firebaseApp }}>
            {children}
        </ReferenceDataContext.Provider>
    );
};